<script setup lang="ts">
import type { Area } from "~/types";

const props = defineProps<{
  prefecture?: string;
  // 都道府県のみ
  // prefArea: Area,
  // 市区町村
  shikuAreas: Area[];
}>();

// 都道府県別のリンクデーターも作りたいだけ
const areas = computed<Area[]>(() => {
  const result = [];

  if (props.prefecture) {
    const prefAreas = props.shikuAreas.filter(
      (area) => area.pref === props.prefecture,
    );
    const prefCount = prefAreas.reduce((acc, area) => acc + area.count, 0);
    const prefArea = {
      pref: props.prefecture,
      city: "",
      count: prefCount,
    };
    result.push(prefArea, ...prefAreas);
  } else {
    result.push(...props.shikuAreas);
  }

  return result;
});

// その他の場合は、URLをotherにする
function linkCity(city: string) {
  if ("その他" == city) return "other";
  return city;
}

const linkRefs = ref<any[]>([]);

// linkRefsを使って、リンクの位置を取得する
// .is-activeの位置を取得する
// その位置をスクロールする

onMounted(() => {
  const links = linkRefs.value;
  const activeLink = links.find((link) => link.querySelector(".is-active"));
  if (activeLink) {
    activeLink.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
});
</script>

<template>
  <li ref="linkRefs" v-for="area in areas" class="mb-2">
    <SekouAreaTag
      :prefecture="area.pref"
      :city="area.city"
      :count="area.count"
    />
  </li>
</template>

<style scoped>
.is-active {
  background-color: #f7fafc;
}
</style>
